<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <div class="register_step flex">
          <div class="step_box flex on">
            <div class="num">1</div>
            <p>申し込み<br class="sp">フォーム</p>
          </div>
          <div class="step_box flex">
            <div class="num">2</div>
            <p>申し込み内容の<br class="sp">確認</p>
          </div>
          <div class="step_box flex">
            <div class="num">3</div>
            <p>決済情報の<br class="sp">入力</p>
          </div>
          <div class="step_box flex">
            <div class="num">4</div>
            <p>参加申し込みを<br class="sp">受け付けました</p>
          </div>
        </div>

        <div class="cts_box">
          <h2 class="sub_ttl"><span>申し込み {{ model.title }}</span></h2>
          <h3 class="min_ttl">研修会概要</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>名称</dt>
              <dd>{{ model.title }}</dd>
            </dl>

            <dl class="flex">
              <dt>開催日</dt>
              <dd>{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }} {{ model.open_time }}</dd>
            </dl>
            <dl class="flex" v-if="model.place_text">
              <dt>会場</dt>
              <dd v-html="model.place_text"></dd>
            </dl>
            <dl class="flex" v-if="model.join_type === 'join_type_both' || model.join_type === 'join_type_offline'">
              <dt>価格（現地）</dt>
              <dd>
                {{ delimited(model.rank_price) }}円･税込
              </dd>
            </dl>
            <dl class="flex" v-if="model.join_type === 'join_type_online'">
              <dt>価格（オンライン）</dt>
              <dd>
                {{ delimited(model.rank_price_online) }}円･税込
              </dd>
            </dl>
          </div>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">研修会メニュー</h3>
          <div class="reg_set first">
            <div class="event_entry_list">
              <div class="tax_ttl flex">
                <h4>参加を希望する研修会のメニューを選択してください。</h4>
                <p class="tax_caution bold red">※表示はすべて税込価格です</p>
              </div>
              <div class="event_entry_box flex check_radio"
                   v-if="model.join_type === 'join_type_both' || model.join_type === 'join_type_offline'">
                <input type="radio" id="event01" v-model="user.join_type" @change="priceChange"
                       value="join_type_offline">
                <label class="flex" for="event01">
                  <div class="ttl">研修会参加費(現地)</div>
                  <div class="class">{{ isUserSignedIn() ? current_user.rank_label : '一般' }}</div>
                  <div class="price">¥{{ delimited(model.rank_price) }}</div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
              <div class="event_entry_box flex check_radio"
                   v-if="model.join_type === 'join_type_both' || model.join_type === 'join_type_online'">
                <input type="radio" id="event02" v-model="user.join_type" @change="priceChange"
                       value="join_type_online">
                <label class="flex" for="event02">
                  <div class="ttl">研修会参加費(オンライン)</div>
                  <div class="class">{{ isUserSignedIn() ? current_user.rank_label : '一般' }}</div>
                  <div class="price">¥{{ delimited(model.rank_price_online) }}</div>
                  <div class="btn"><span></span>
                    <p>選択する</p></div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="cts_box" v-if="fetch_data.coupon">
          <h3 class="min_ttl">利用可能なクーポンがあります</h3>
          <p class="lead_txt">
            現在以下のクーポンが利用できます。クーポンが適用中になっていることをご確認ください。
          </p>
          <div class="scrl_box coupon workshop">
            <div class="inner">
              <ul class="coupon_list">
                <li class="flex">
                  <div class="thumb"><img v-bind:src="fetch_data.coupon.image_file_url" alt=""></div>
                  <div class="detail">
                    <div class="head flex">
                      <h3>{{ fetch_data.coupon.title }}</h3>
                      <div class="dead_line">有効期限：<span>{{ moment(fetch_data.coupon.close_date, 'YYYY年MM月DD日') }}</span>
                      </div>
                    </div>
                    <p>
                      {{ fetch_data.coupon.description }}
                    </p>
                  </div>
                  <div class="btn_wrap">
                    <a href="javascript:void(0);" v-if="!user.use_coupon" @click="useCouponClicked(true)"
                       class="btn" v-bind:class="{disabled: !useCouponPrice()}">クーポンを利用する</a>
                    <a href="javascript:void(0);" v-else @click="useCouponClicked(false)"
                       class="btn active">クーポン適用中</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="cts_box claim workshop" v-if="isUserSignedIn()" v-bind:class="{ 'point-disabled': !pointUsable() }">
          <h3 class="min_ttl">ポイントの利用</h3>
          <p class="lead_txt">ポイントを利用する場合は、ポイント数を入力の上「ポイントを利用する」ボタンを押してください。</p>
          <table class=""><!-- ポイント利用できない場合はclass="point_none" を追加 -->
            <tbody>
            <tr class="head">
            </tr>
            <tr>
              <td class="date">現在のポイント数</td>
              <td class="price">{{ delimited(model.total_points) }}<span>ポイント</span></td>
              <td class="point flex">
                <h3 class="sp">ポイント利用額（1ポイント＝1円）</h3>
                <p>ご利用ポイントを入力</p>
                <div class="point_input">
                  <input type="text" v-model="user.use_point"
                         @input="user.use_point = replaceFullToNumber(user.use_point)"
                         v-bind:disabled="model.total_points === 0"
                         maxlength="6" class="point">
                  <p class="caution">最大 {{ delimited(Math.min(model.total_points, user.usable_points)) }}
                    ポイントまでご利用いただけます</p>
                  <p>1ポイント＝1円として利用できます</p>
                </div>
                <p class="bold">ポイント</p>
              </td>
            </tr>
            <tr>
              <td colspan="3" class="btn">
                <button type="button" class="submit light skeleton"
                        v-bind:disabled="model.total_points === 0"
                        @click="usePointClicked">ポイントを利用する
                </button>
              </td>
              <!--<td colspan="3" class="btn"><input type="submit" class="submit light skeleton" value="現在ポイントの利用はできません" disabled></td> ポイント利用できない場合-->
            </tr>
            </tbody>
          </table>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">お会計内容</h3>
          <p class="lead_txt">参加を希望されるすべての研修会の合計額を表示しています。</p>
          <div class="register_box">
            <dl class="flex">
              <dt>{{ model.title }}</dt>
              <dd class="item" v-if="isUserSignedIn()">{{ current_user.rank_label }}</dd>
              <dd class="item" v-if="!isUserSignedIn()">一般</dd>
              <dd class="price">¥{{ delimited(user.price) }}</dd>
            </dl>
            <dl class="flex" v-if="user.use_coupon">
              <dt>クーポンのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">¥-{{ delimited(discountCouponPrice()) }}<span v-if="false">受講額 ¥-{{ delimited(fetch_data.coupon.price) }}%オフ</span></dd>
            </dl>
            <dl class="flex" v-if="user.point">
              <dt>ポイントのご利用</dt>
              <dd class="item"></dd>
              <dd class="price">-{{ delimited(user.point) }}</dd>
            </dl>
            <dl class="flex total">
              <dt></dt>
              <dd class="item">お会計合計：</dd>
              <dd class="price">¥{{ delimited(user.total_price) }}</dd>
            </dl>
          </div>
        </div>

        <div class="cts_box" id="entry_form">
          <h3 class="min_ttl">申し込みフォーム</h3>
          <form @submit="update" id="form">
            <template v-if="isUserSignedIn()">
              <div class="df_table">
                <dl class="flex">
                  <dt>おなまえ</dt>
                  <dd>{{ current_user.last_name }} {{ current_user.first_name }}</dd>
                </dl>
                <dl class="flex">
                  <dt>塾生番号</dt>
                  <dd>{{ current_user.number }}</dd>
                </dl>
              </div>

              <div class="button_wrap mt50">
                <p class="alnC mb20" v-if="false">
                  すべての項目に入力したら<br class="sp">こちらのボタンを押してください。
                </p>
                <input type="submit" class="submit light arrow" value="申し込み内容の確認">
              </div>
            </template>

            <template v-else>
              <p class="lead_txt">
                下記フォームに必要事項をご入力の上、画面下の「申し込み内容の確認」ボタンを押してください。<br>
                <span class="bold blue">「*」のついている箇所は必須項目です。</span>
              </p>
              <div class="msg_box error" v-if="errors">
                <h3>ご入力内容にエラー項目があります</h3>
                <p>エラー個所は赤く表示され、エラー理由がすぐ下に赤字で示されます。<br>お手数ですが、エラー項目を修正してからボタンを押してください。</p>
              </div>

              <div class="input_set name">
                <div class="label">おなまえ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓</div>
                    <input type="text" maxlength="16" placeholder="例：山田" v-model="user.last_name"
                           @input="user.last_name = replaceHalfToFull(user.last_name)"
                           v-bind:class="{error: validation_errors.last_name}">
                    <ValidationErrors :errors="validation_errors.last_name" v-if="validation_errors.last_name"/>
                  </div>
                  <div class="field">
                    <div class="label">名</div>
                    <input type="text" maxlength="16" placeholder="例：太郎" v-model="user.first_name"
                           @input="user.first_name = replaceHalfToFull(user.first_name)"
                           v-bind:class="{error: validation_errors.first_name}">
                    <ValidationErrors :errors="validation_errors.first_name" v-if="validation_errors.first_name"/>
                  </div>
                </div>
              </div>

              <div class="input_set kana">
                <div class="label">フリガナ<span>*</span></div>
                <div class="input_fields flex">
                  <div class="field">
                    <div class="label">姓（全角カナ）</div>
                    <input type="text" maxlength="16" placeholder="例：ヤマダ" v-model="user.last_name_kana"
                           @input="user.last_name_kana = replaceHiraToKana(user.last_name_kana)"
                           v-bind:class="{error: validation_errors.last_name_kana}">
                    <ValidationErrors :errors="validation_errors.last_name_kana"
                                      v-if="validation_errors.last_name_kana"/>
                  </div>
                  <div class="field">
                    <div class="label">名（全角カナ）</div>
                    <input type="text" maxlength="16" placeholder="例：タロウ" v-model="user.first_name_kana"
                           @input="user.first_name_kana = replaceHiraToKana(user.first_name_kana)"
                           v-bind:class="{error: validation_errors.first_name_kana}">
                    <ValidationErrors :errors="validation_errors.first_name_kana"
                                      v-if="validation_errors.first_name_kana"/>
                  </div>
                </div>
              </div>

              <div class="input_set">
                <div class="label">性別<span>*</span></div>
                <div class="radio">
                  <input id="radio01" type="radio" name="gender" v-model="user.gender" value="male">
                  <label for="radio01">男性</label>
                  <input id="radio02" type="radio" name="gender" v-model="user.gender" value="female">
                  <label for="radio02">女性</label>
                  <input id="radio03" type="radio" name="gender" v-model="user.gender" value="secret">
                  <label for="radio03">その他</label>
                </div>
                <ValidationErrors :errors="validation_errors.gender" v-if="validation_errors.gender"/>
              </div>

              <div class="input_set birth">
                <div class="label">生年月日<span>*</span></div>
                <p>すべての欄に半角数字でご入力下さい。</p>
                <div class="input_fields flex">
                  <div class="field">
                    <input type="text" placeholder="年" v-model="user.birthday_year"
                           @input="user.birthday_year = replaceFullToHalf(user.birthday_year)"
                           v-bind:class="{error: validation_errors.birthday_year}">
                    <ValidationErrors :errors="validation_errors.birthday_year" v-if="validation_errors.birthday_year"/>
                  </div>
                  <div class="field">
                    <input type="text" placeholder="年" v-model="user.birthday_month"
                           @input="user.birthday_month = replaceFullToHalf(user.birthday_month)"
                           v-bind:class="{error: validation_errors.birthday_month}">
                    <ValidationErrors :errors="validation_errors.birthday_month"
                                      v-if="validation_errors.birthday_month"/>
                  </div>
                  <div class="field">
                    <input type="text" placeholder="年" v-model="user.birthday_day"
                           @input="user.birthday_day = replaceFullToHalf(user.birthday_day)"
                           v-bind:class="{error: validation_errors.birthday_day}">
                    <ValidationErrors :errors="validation_errors.birthday_day" v-if="validation_errors.birthday_day"/>
                  </div>
                </div>
              </div>

              <div class="input_set email">
                <div class="label">連絡先メールアドレス<span>*</span></div>
                <input type="email" v-model="user.email"
                       @input="user.email = replaceFullToHalf(user.email)"
                       v-bind:class="{error: validation_errors.email}">
                <ValidationErrors :errors="validation_errors.email" v-if="validation_errors.email"/>
              </div>

              <div class="input_set email">
                <div class="label">連絡先メールアドレス（確認）<span>*</span></div>
                <input type="email" v-model="user.email_confirmation"
                       @input="user.email_confirmation = replaceFullToHalf(user.email_confirmation)"
                       v-bind:class="{error: validation_errors.email_confirmation}">
                <ValidationErrors :errors="validation_errors.email_confirmation"
                                  v-if="validation_errors.email_confirmation"/>
              </div>

              <div class="input_set email" v-if="false">
                <div class="label">第二メールアドレス（オプション）<span></span></div>
                <input type="email" v-model="user.email2" v-bind:class="{error: validation_errors.email2}"
                       placeholder="連絡先メールアドレスとは異なるアドレスへのメール送付をご希望の場合のみ記入">
                <ValidationErrors :errors="validation_errors.email" v-if="validation_errors.email"/>
              </div>

              <div class="input_set phone">
                <div class="label">連絡先電話番号<span>*</span></div>
                <p>（半角数字で日中連絡の取れるお電話番号をご入力下さい。）</p>
                <input type="tel" v-model="user.phone_number"
                       @input="user.phone_number = replaceFullToHalf(user.phone_number)"
                       v-bind:class="{error: validation_errors.phone_number}" placeholder="例：09012345678"
                       value="09012345678">
                <ValidationErrors :errors="validation_errors.phone_number" v-if="validation_errors.phone_number"/>
              </div>

              <div class="reg_set">
                <div class="input_set addr">
                  <div class="label">郵便番号<span>*</span></div>
                  <p>（半角数字）</p>
                  <div class="input_fields flex">
                    <div class="field">
                      <input type="text" v-model="user.zip" v-bind:class="{error: validation_errors.zip}"
                             @input="user.zip = replaceFullToHalf(user.zip)"
                             name="zip"
                             placeholder="例：100-1234">
                      <validation-errors :errors="validation_errors.zip"
                                         v-if="validation_errors.zip"></validation-errors>
                    </div>
                    <div class="field">
                      <button type="button" class="ajaxzip3" v-on:click="zipSearch">郵便番号から住所を取得</button>
                    </div>
                  </div>
                </div>

                <div class="input_set addr">
                  <div class="label">都道府県名<span>*</span></div>
                  <p>（選択して下さい ※海外にお住まいの方は「日本国外に居住」を選択のうえ国内の送付先住所を「送付先住所指定フォーム」にご入力ください）</p>
                  <PrefectureSelect :prefecture="user.prefecture" name="prefecture"
                                    v-bind:class="{error: validation_errors.prefecture}"
                                    v-on:changed="prefectureChanged"/>
                  <validation-errors :errors="validation_errors.prefecture"
                                     v-if="validation_errors.prefecture"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">市区町村<span>*</span></div>
                  <input type="text" v-model="user.address1" v-bind:class="{error: validation_errors.address1}"
                         @input="user.address1 = replaceHalfToFull(user.address1)"
                         placeholder="例：新宿区">
                  <validation-errors :errors="validation_errors.address1"
                                     v-if="validation_errors.address1"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">地名・番地<span>*</span></div>
                  <input type="text" v-model="user.address2" v-bind:class="{error: validation_errors.address2}"
                         @input="user.address2 = replaceHalfToFull(user.address2)"
                         class="address" placeholder="例：３丁目１３番地">
                  <validation-errors :errors="validation_errors.address2"
                                     v-if="validation_errors.address2"></validation-errors>
                </div>

                <div class="input_set addr">
                  <div class="label">建物名・部屋番号<span></span></div>
                  <input type="text" v-model="user.address3" v-bind:class="{error: validation_errors.address3}"
                         @input="user.address3 = replaceHalfToFull(user.address3)"
                         class="addr4" placeholder="例：複眼ビル１１１号室">
                  <validation-errors :errors="validation_errors.address3"
                                     v-if="validation_errors.address3"></validation-errors>
                </div>

                <div class="input_set" v-if="false">
                  <div class="label">コメント</div>
                  <p>（※商品のお届けに関して申し伝えることがございましたらご記入ください）</p>
                  <textarea name="" placeholder="" v-bind:class="{error: validation_errors.memo}"
                            v-model="user.memo"></textarea>
                  <ValidationErrors :errors="validation_errors.memo"
                                    v-if="validation_errors.memo"></ValidationErrors>
                </div>
              </div>

              <div class="button_wrap mt50">
                <p class="alnC mb20">
                  すべての項目に入力したら<br class="sp">こちらのボタンを押してください。
                </p>
                <input type="submit" class="submit light arrow" value="申し込み内容の確認">
              </div>
            </template>
          </form>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import ValidationErrors from "@/components/ValidationErrors";
import PrefectureSelect from "@/components/PrefectureSelect";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
    PrefectureSelect,
  },
  data() {
    let _init = false
    if (!this.$store.state.user) {
      if (this.isUserSignedIn()) { // ログイン中
        this.$store.state.user = this.$route.meta.current_user
        this.$store.state.user.user_id = this.$store.state.user.id
        this.$store.state.user.email_confirmation = this.$store.state.user.email
        this.$store.state.user.memo = null
        if (this.$store.state.user.enable_primary_address) {
          this.$store.state.user.zip = this.$store.state.user.opt_zip
          this.$store.state.user.prefecture = this.$store.state.user.opt_prefecture
          this.$store.state.user.address1 = this.$store.state.user.opt_address1
          this.$store.state.user.address2 = this.$store.state.user.opt_address2
          this.$store.state.user.address3 = this.$store.state.user.opt_address3
        }
      } else {
        this.$store.state.user = {
          user_id: null,
          address1: null,
          address2: null,
          address3: null,
          birthday_day: null,
          birthday_month: null,
          birthday_year: null,
          email: null,
          email_confirmation: null,
          first_name: null,
          first_name_kana: null,
          gender: null,
          last_name: null,
          last_name_kana: null,
          memo: null,
          phone_number: null,
          prefecture: null,
          zip: null,
        }
      }
      this.$store.state.user.workshop_id = this.$route.params.id
      this.$store.state.user.price = null
      this.$store.state.user.join_type = 'join_type_offline';

      this.$store.state.user.use_coupon = false
      this.$store.state.user.usable_points = 0
      this.$store.state.user.use_point = null
      this.$store.state.user.point = 0
      this.$store.state.user.price = 0
      this.$store.state.user.use_coupon_price = 0
      this.$store.state.user.total_price = 0
      _init = true
    } else {
      _init = false
    }

    return {
      id: this.$route.params.id,
      fetch_data: null,
      model: null,
      errors: false,
      validation_errors: {},
      user: this.$store.state.user,
      init: _init
    }
  },
  mounted() {
    // form token
    this.setFormToken('Workshop')

    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}workshop/${this.id}.json`, {
            params: {
              draft: this.$route.query.draft
            }
          })
          .then(response => {
            this.fetch_data = response.data
            this.model = response.data.workshop

            if (!this.model.join_rank) {
              // 参加できない
              if (this.isUserSignedIn()) { // ログイン中
                this.$router.push({name: 'UsersCustomerRank'})
              } else { // 非ログイン中
                this.$router.push({name: 'UsersSignIn'})
              }
            }
            if (this.init) {
              if (this.model.join_type === 'join_type_offline') {
                this.user.join_type = 'join_type_offline'
              } else if (this.model.join_type === 'join_type_online') {
                this.user.join_type = 'join_type_online'
              } else if (this.model.join_type === 'join_type_both') {
                this.user.join_type = 'join_type_offline'
              }
            }
            if (this.fetch_data.coupon != null) {
              this.useCouponClicked(true)
            }
            this.priceChange(this.init)

            if (!this.model.request_enabled && !this.model.lottery_enabled) {
              setTimeout(() => {
                alert('現在、こちらにはお申込みが出来ません。\n前のページに戻ります。')
                this.$router.go(-1)
              }, 500)
            }
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    prefectureChanged(prefecture) {
      this.user.prefecture = prefecture
      //this.$forceUpdate()
    },
    optPrefectureChanged(prefecture) {
      this.user.opt_prefecture = prefecture
      //this.$forceUpdate()
    },
    zipSearch() {
      if (this.user.zip) {
        this.addressSearch(this.user.zip)
            .then(data => {
              this.user.prefecture = data.pref
              this.user.address1 = data.address
              this.$forceUpdate()
            })
      }
    },

    useCouponClicked(active) {
      this.user.use_coupon = active
      if (this.user.use_coupon) {
        this.user.coupon_id = this.fetch_data.coupon.id
        this.user.coupon_price = this.fetch_data.coupon.price
      } else {
        this.user.coupon_id = this.user.coupon_price = null
      }
      this.priceChange()
    },

    priceChange(init = true) {
      if (init) this.user.point = this.user.use_point = null
      if (this.user.join_type === 'join_type_offline') {
        this.user.price = this.model.rank_price
        this.user.total_price = this.useCouponPrice() - this.user.point
        // 使えるポイント値を計算
        this.user.usable_points = this.model.point_usable ? this.useCouponPrice() : 0
      } else {
        this.user.price = this.model.rank_price_online
        this.user.total_price = this.useCouponPrice() - this.user.point
        // 使えるポイント値を計算
        this.user.usable_points = this.model.online_point_usable ? this.useCouponPrice() : 0
      }
    },

    usePointClicked() {
      if (isNaN(this.user.use_point)) {
        this.user.use_point = null
        this.user.point = 0
        this.priceChange()
        return
      }
      this.user.use_point = Number(this.user.use_point)
      const usable_points = Math.min(this.model.total_points, this.user.usable_points)
      if (this.user.use_point > usable_points) {
        this.user.use_point = usable_points
      }
      if (this.user.use_point === 0) {
        this.user.use_point = null
        this.user.point = 0
        this.priceChange()
        return
      }

      this.user.point = this.user.use_point
      if (this.user.use_point > this.user.usable_points) {
        this.user.point = this.user.use_point = this.user.usable_points
      }
      if (this.user.use_point > this.user.price) {
        this.user.point = this.user.use_point = this.user.price
      }
      this.user.total_price = this.useCouponPrice() - this.user.point
    },

    discountCouponPrice() {
      if (!this.user.use_coupon) {
        return 0
      } else {
        return Math.min(this.user.price, this.fetch_data.coupon.price)
      }
    },

    useCouponPrice() {
      if (!this.user.use_coupon) {
        return this.user.price
      } else {
        return Math.max(this.user.price - this.fetch_data.coupon.price, 0)
      }
    },

    pointUsable() {
      return this.user.usable_points > 0
    },

    update(event) {
      event.preventDefault()
      this.axios
          .post(`${this.env.api_base_url}workshop/validate.json`, {
            workshop_user: this.user,
          })
          .then(() => {
            this.$router.push({name: 'WorkshopConfirm', params: {id: this.model.id}, query: this.$route.query})
          })
          .catch((error) => {
            // this.errorAjax(error)
            this.scrollTo(`#form`)
            if (error.response.data.errors) {
              this.validation_errors = error.response.data.validation_errors
              this.errors = true
            }
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>

<style>
.point-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
